<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">{{ title }}</h1>
    <v-toolbar flat color="toolbar-action transparent">
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="addItem">New</v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="paperworkSets"
      @click:row="editItem"
      :loading="loading"
      :options.sync="options"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:item.updated_at="{ item }">
        {{ formatDateAndTime(item.updated_at) }}
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ formatDateAndTime(item.created_at) }}
      </template>

      <template v-slot:item.date_due="{ item }"> {{ formatDate(item.date_due) }} </template>
    </v-data-table>
    <Paginate :meta="meta" v-on:paginate="paginateAction" />

    <v-dialog width="640" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showManageDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Paperwork Set Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showManageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <PaperworkSetDetailForm
            :paperworkset_id="paperworkset_id"
            :isvisible="showManageDialog"
            @closed="onMangageDialogClosed"
          />
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';
import PaperworkSetDetailForm from '@/views/PaperworkSetDetailForm';
import Paginate from '@/views/Paginate';
import { dateMixin } from '@/mixins/dateMixin';

export default {
  name: 'SettingsPaperworkSetList',
  components: {
    PaperworkSetDetailForm,
    Paginate
  },
  mixins: [dateMixin],
  methods: {
    addItem: function () {
      this.paperworkset_id = 'new';
      this.showManageDialog = true;
    },
    editItem(item) {
      this.paperworkset_id = item.id;
      this.showManageDialog = true;
    },
    onMangageDialogClosed() {
      this.showManageDialog = false;
      this.load();
    },
    load() {
      store.dispatch('paperworkset/list', {
        page: this.options.page,
        per_page: this.items_per_page,
        sort_by: this.options.sortBy[0] ?? 'paperwork_group',
        sort_desc: this.options.sortDesc[0] ?? false
      });
    },
    paginateAction(item) {
      if (item == 'next') {
        store.dispatch('paperworkset/paginate', this.links.next);
      } else if (item == 'previous') {
        store.dispatch('paperworkset/paginate', this.links.prev);
      }
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters('paperworkset', ['loading', 'error', 'paperworkSets', 'meta', 'links'])
  },
  watch: {
    options: {
      handler() {
        if (this.options.init) {
          this.load();
        } else {
          this.options.init = true;
        }
      },
      deep: true
    }
  },
  data: () => ({
    title: 'Paperwork Sets',
    showManageDialog: false,
    showDeleteConfirmDialog: false,
    paperworkset_id: null,
    items_per_page: 10,
    options: {},
    headers: [
      { text: 'Group', value: 'paperwork_group' },
      { text: 'Template', value: 'paperwork_template' },
      { text: 'Modified', value: 'updated_at', align: 'right' },
      { text: 'Created', value: 'created_at', align: 'right' }
    ],
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'Settings',
        exact: true,
        to: '/settings'
      },
      {
        text: 'Setting',
        disabled: true,
        exact: true,
        to: '/setting-paperwork-set-list/'
      }
    ]
  })
};
</script>
