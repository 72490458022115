import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('h1',{staticClass:"headline pb-4 mx-lg-auto"},[_vm._v(_vm._s(_vm.title))]),_c(VToolbar,{attrs:{"flat":"","color":"toolbar-action transparent"}},[_c(VSpacer),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary v-btn--new","dark":""},on:{"click":function($event){return _vm.newItem()}}},[_vm._v("New")])],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"options":_vm.options,"loading-text":"Loading... Please wait","hide-default-footer":""},on:{"click:row":_vm.editItem,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateAndTime(item.updated_at))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateAndTime(item.created_at))+" ")]}}])}),_c('Paginate',{attrs:{"meta":_vm.meta},on:{"paginate":_vm.paginateAction}}),_c(VDialog,{attrs:{"width":"640","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.showManageDialog),callback:function ($$v) {_vm.showManageDialog=$$v},expression:"showManageDialog"}},[[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title)+" Details")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showManageDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c('SettingsTitleDialog',{attrs:{"loading":_vm.loading},on:{"input":function($event){return _vm.saveAction()}},model:{value:(_vm.listItem),callback:function ($$v) {_vm.listItem=$$v},expression:"listItem"}})],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }