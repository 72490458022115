import * as API from '@/services/API';

export default {
  get(id) {
    return API.apiClient.get(`/airlines/${id}`);
  },
  list(options) {
    const query = API.apiHelper.getQueryStringFromObject(options);
    return API.apiClient.get(`/airlines/${query}`);
  },
  async delete(id) {
    return API.apiClient.delete(`/airlines/${id}`);
  },
  paginate(link) {
    return API.apiClient.get(link);
  },
  async save(id, data) {
    if (id == 0) {
      return API.apiClient.post(`/airlines`, data);
    } else {
      return API.apiClient.put(`/airlines/${id}`, data);
    }
  },
  search(options) {
    const query = API.apiHelper.getQueryStringFromObject(options);
    return API.apiClient.get(`/airlines/search${query}`);
  }
};
