<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">{{ title }}</h1>

    <v-toolbar flat color="toolbar-action transparent">
      <v-spacer></v-spacer>
      <v-btn color="primary v-btn--new" dark class="ma-2" @click="newItem()">New</v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="list"
      @click:row="editItem"
      :loading="loading"
      :options.sync="options"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:item.updated_at="{ item }">
        {{ formatDateAndTime(item.updated_at) }}
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ formatDateAndTime(item.created_at) }}
      </template>
    </v-data-table>
    <Paginate :meta="meta" v-on:paginate="paginateAction" />
    <v-dialog width="640" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showManageDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>{{ title }} Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showManageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container>
            <SettingsTitleDialog v-model="listItem" :loading="loading" @input="saveAction()" />
          </v-container>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import Paginate from '@/views/Paginate';
import AirlineService from '@/services/AirlineService';
import AgeLevelService from '@/services/AgeLevelService';
import FlightTypeService from '@/services/FlightTypeService';
import GenderService from '@/services/GenderService';
import GroupTypeService from '@/services/GroupTypeService';
import OrganisationTypeService from '@/services/OrganisationTypeService';
import StaffTypeService from '@/services/StaffTypeService';
import StaffRoleService from '@/services/StaffRoleService';
import PaperworkGroupService from '@/services/PaperworkGroupService';
import PaperworkTemplateService from '@/services/PaperworkTemplateService';
import FamilyMemberRelationshipService from '@/services/FamilyMemberRelationshipService';
import SettingsTitleDialog from '@/views/dialogs/settings/SettingsTitleDialog';
import store from '@/store/index';
import { dateMixin } from '@/mixins/dateMixin';

export default {
  name: 'SettingLists',
  components: {
    Paginate,
    SettingsTitleDialog
  },
  mixins: [dateMixin],
  mounted: function () {
    this.item = this.items.find(item => item.id === parseInt(this.$route.params.id));
    this.title = this.item.title;
    this.load();
  },
  methods: {
    load() {
      if (this.item && this.item.service != null) {
        this.loading = true;
        this.service = this.item.service;
        this.service
          .list({
            page: this.options.page ?? 1,
            sort_by: this.options.sortBy[0] ?? 'title',
            sort_desc: this.options.sortDesc[0] ?? false
          })
          .then(response => {
            this.list = response.data.data;
            this.meta = response.data.meta;
            this.links = response.data.links;
            this.loading = false;
          })
          .catch(error => {
            store.dispatch('ui/snackbarError', error);
            this.loading = false;
          });
      }
    },
    paginateAction(item) {
      this.loading = true;
      if (item == 'next') {
        this.service
          .paginate(this.links.next)
          .then(response => {
            this.list = response.data.data;
            this.meta = response.data.meta;
            this.links = response.data.links;
            this.loading = false;
          })
          .catch(error => {
            store.dispatch('ui/snackbarError', error, { root: true });
            this.loading = false;
          });
      } else if (item == 'previous') {
        this.service
          .paginate(this.links.prev)
          .then(response => {
            this.list = response.data.data;
            this.meta = response.data.meta;
            this.links = response.data.links;
            this.loading = false;
          })
          .catch(error => {
            store.dispatch('ui/snackbarError', error, { root: true });
            this.loading = false;
          });
      }
    },
    newItem() {
      this.listItem = {
        id: 0,
        title: ''
      };
      this.showManageDialog = true;
    },
    editItem(item) {
      this.listItem = { ...item };
      this.showManageDialog = true;
    },
    saveAction() {
      if (this.service && this.listItem.id >= 0) {
        this.loading = true;
        this.showManageDialog = false;
        this.service
          .save(this.listItem.id, this.listItem)
          .then(() => {
            this.load();
            this.loading = false;
          })
          .catch(error => {
            store.dispatch('ui/snackbarError', error, { root: true });
            this.loading = false;
          });
      }
    }
  },
  watch: {
    options: {
      handler() {
        if (this.options.init) {
          this.load();
        } else {
          this.options.init = true;
        }
      },
      deep: true
    }
  },
  data: () => ({
    loading: false,
    title: 'Settings',
    headers: [
      { text: 'Title', value: 'title' },
      { text: 'Modified', value: 'updated_at', align: 'right' },
      { text: 'Created', value: 'created_at', align: 'right' }
    ],
    meta: null,
    list: [],
    listItem: null,
    showManageDialog: false,
    service: null,
    item: null,
    links: null,
    options: {},
    items: [
      {
        id: 1,
        title: 'Age Level',
        service: AgeLevelService
      },
      {
        id: 2,
        title: 'Airline',
        service: AirlineService
      },
      {
        id: 10,
        title: 'Flight Type',
        service: FlightTypeService
      },
      {
        id: 3,
        title: 'Gender',
        service: GenderService
      },
      {
        id: 4,
        title: 'Group Type',
        service: GroupTypeService
      },
      {
        id: 12,
        title: 'Organisation Type',
        service: OrganisationTypeService
      },
      {
        id: 9,
        title: 'Family Member Relationship',
        service: FamilyMemberRelationshipService
      },
      {
        id: 5,
        title: 'Paperwork Template',
        service: PaperworkTemplateService
      },
      {
        id: 6,
        title: 'Paperwork Group',
        service: PaperworkGroupService
      },
      {
        id: 7,
        title: 'Staff Type',
        service: StaffTypeService
      },
      {
        id: 8,
        title: 'Staff Role',
        service: StaffRoleService
      }
    ],
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'Settings',
        exact: true,
        to: '/settings'
      },
      {
        text: 'Setting',
        disabled: true,
        exact: true,
        to: '/settings/:id'
      }
    ]
  })
};
</script>
