import * as API from '@/services/API';

export default {
  get(id) {
    return API.apiClient.get(`/flight-types/${id}`);
  },
  list(options) {
    const query = API.apiHelper.getQueryStringFromObject(options);
    return API.apiClient.get(`/flight-types/${query}`);
  },
  async delete(id) {
    return API.apiClient.delete(`/flight-types/${id}`);
  },
  paginate(link) {
    return API.apiClient.get(link);
  },
  async save(id, data) {
    if (id == 0) {
      return API.apiClient.post(`/flight-types`, data);
    } else {
      return API.apiClient.put(`/flight-types/${id}`, data);
    }
  },
  search(options) {
    const query = API.apiHelper.getQueryStringFromObject(options);
    return API.apiClient.get(`/flight-types/search${query}`);
  }
};
