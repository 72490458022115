<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid, dirty }">
      <v-form>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="Group" rules="required">
              <v-select
                v-model="paperworksetLocal.paperwork_group_id"
                :disabled="loading"
                :items="lists.groups"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Group"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Template" rules="required">
              <v-select
                v-model="paperworksetLocal.paperwork_template_id"
                :disabled="loading"
                :items="lists.templates"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Template"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Days" rules="required,between:-900,900">
              <v-text-field v-model="paperworksetLocal.days" :disabled="loading" label="Days"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Check Date">
              <v-switch v-model="paperworksetLocal.check_date" label="Check Date"></v-switch>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Auto Date">
              <v-switch v-model="paperworksetLocal.auto_date" label="Auto Date"></v-switch>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Use Start Date">
              <v-switch v-model="paperworksetLocal.use_start_date" label="Use Start Date"></v-switch>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, integer, between } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';

extend('required', required);
extend('integer', integer);
extend('between', between);

export default {
  name: 'PaperworkSetDetailForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: ['paperworkset_id', 'isvisible'],
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('paperworkset', ['loading', 'paperworkSet', 'error', 'success', 'meta', 'lists']),
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        templates: [],
        groups: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save.
     */
    paperworksetLocal: function () {
      return loadDash.cloneDeep(this.paperworkSet);
    }
  },
  methods: {
    /**
     * Load the paperworkset
     */
    load: function () {
      this.resetValidation();
      store.dispatch('paperworkset/get', { id: this.paperworkset_id });
    },
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result) {
        await store.dispatch('paperworkset/save', {
          id: this.paperworksetLocal.id,
          paperworkset: this.paperworksetLocal
        });
      }

      this.$emit('closed');
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    }
  },
  mounted: async function () {
    this.load();
  },
  watch: {
    isvisible: function (newValue) {
      if (newValue) {
        this.load();
      }
    }
  }
};
</script>
