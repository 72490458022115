<template>
  <v-container>
    <ValidationObserver ref="objects">
      <v-form slot-scope="{ invalid, dirty }">
        <v-row>
          <v-col cols="12">
            <ValidationProvider name="Title" rules="required|min:2|max:255">
              <v-text-field
                v-model="value.title"
                :disabled="loading"
                label="Title"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction()" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min } from 'vee-validate/dist/rules';

extend('required', required);
extend('max', max);
extend('min', min);

export default {
  name: 'SettingsTitleDialog',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    /**
     * The title of the object
     */
    value: {
      type: Object
    },
    /**
     * Loading flag
     */
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * Save method for the form
     */
    saveAction() {
      this.$emit('input', {
        id: this.value.id,
        title: this.value.title
      });
      this.resetValidation();
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    }
  }
};
</script>
