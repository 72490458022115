import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.groups,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","error-messages":errors,"label":"Group","clearable":""},model:{value:(_vm.paperworksetLocal.paperwork_group_id),callback:function ($$v) {_vm.$set(_vm.paperworksetLocal, "paperwork_group_id", $$v)},expression:"paperworksetLocal.paperwork_group_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.templates,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","error-messages":errors,"label":"Template","clearable":""},model:{value:(_vm.paperworksetLocal.paperwork_template_id),callback:function ($$v) {_vm.$set(_vm.paperworksetLocal, "paperwork_template_id", $$v)},expression:"paperworksetLocal.paperwork_template_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Days","rules":"required,between:-900,900"}},[_c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Days"},model:{value:(_vm.paperworksetLocal.days),callback:function ($$v) {_vm.$set(_vm.paperworksetLocal, "days", $$v)},expression:"paperworksetLocal.days"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Check Date"}},[_c(VSwitch,{attrs:{"label":"Check Date"},model:{value:(_vm.paperworksetLocal.check_date),callback:function ($$v) {_vm.$set(_vm.paperworksetLocal, "check_date", $$v)},expression:"paperworksetLocal.check_date"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Auto Date"}},[_c(VSwitch,{attrs:{"label":"Auto Date"},model:{value:(_vm.paperworksetLocal.auto_date),callback:function ($$v) {_vm.$set(_vm.paperworksetLocal, "auto_date", $$v)},expression:"paperworksetLocal.auto_date"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Use Start Date"}},[_c(VSwitch,{attrs:{"label":"Use Start Date"},model:{value:(_vm.paperworksetLocal.use_start_date),callback:function ($$v) {_vm.$set(_vm.paperworksetLocal, "use_start_date", $$v)},expression:"paperworksetLocal.use_start_date"}})],1)],1)],1),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid || !dirty},on:{"click":_vm.saveAction}},[_vm._v("Save")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }